<template>
  <Carousel
    v-if="banners.length"
    class="promo-list-container"
    mobile-scroll-mode="button"
    :total-page="totalPage || 1"
    :current-page="currentPage"
    @prev="startIdx = startIdx - 1"
    @next="startIdx = startIdx + 1"
  >
    <div
      v-for="banner in banners"
      :key="banner.id"
      class="promo-img"
      :style="{
        backgroundImage: `url(${banner.image_url})`,
      }"
      @click="() => handleClickBanner(banner.target_url)"
    />
  </Carousel>
</template>

<script setup lang="ts">
  /*
    IMPORTANT NOTES
    Carousel implementation in this component is different with the other.
    If other component will change all of the 3 item,
    this component will only change the latest item. One by one.
  */

  import { storeToRefs } from 'pinia'

  import { useBannerStore } from '~/store'
  import { checkIsExternalUrl } from '~/utils'

  const { push } = useRouter()

  const bannerStore = useBannerStore()

  const { state: bannerState } = storeToRefs(bannerStore)

  const isDesktopScreen = ref(true)

  const startIdx = ref(0)
  const endIdx = computed(() => {
    // Only one promo is shown when not in desktop
    if (!isDesktopScreen.value) return startIdx.value + 1

    return startIdx.value + 3
  })

  const currentPage = computed(() => {
    // To hide the previous button in desktop
    if (endIdx.value <= 3 && isDesktopScreen.value) return 1

    return endIdx.value
  })

  const totalPage = computed(() => bannerState.value.homePageBanners.length)
  const banners = computed(() =>
    bannerState.value.homePageBanners.slice(startIdx.value, endIdx.value),
  )

  const handleClickBanner = (url: string) => {
    const isExternalUrl = checkIsExternalUrl(url)

    if (isExternalUrl) {
      window.open(url, '_blank')
    } else {
      push(url)
    }
  }

  onMounted(() => {
    isDesktopScreen.value = window.screen.width > 1024
  })
</script>

<style scoped lang="scss">
  .promo-list-container {
    gap: 20px;

    .promo-img {
      background-size: cover;
      background-position: center;
      width: 33%;
      height: 130px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .promo-list-container {
      .promo-img {
        width: 100%;
        height: 200px;
      }
    }
  }

  @media screen and (max-width: ($mobileLarge + 'px')) {
    .promo-list-container {
      .promo-img {
        height: 125px;
      }
    }
  }
</style>
